import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'quiznet-webapp';

  constructor(
    translateService: TranslateService
  ) {

    const defaultLang = 'de';

    translateService.setDefaultLang(defaultLang);

    const browserLang = translateService.getBrowserLang();

    console.log(`browser language is ${browserLang}`);

    let languageToUse = ['en', 'de'].includes(browserLang) ? browserLang : defaultLang;

    const savedLang = localStorage.getItem('language');
    if(savedLang != null) {
      languageToUse = savedLang
    }

    translateService.use(languageToUse).subscribe(() => {
      console.log(`language changed to ${languageToUse}`);
    }, e => {
      console.error(`unable to load language ${languageToUse}`, e);
    });
  }
}
