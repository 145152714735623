import { Pipe, PipeTransform } from '@angular/core';
import {GameSessionStatus} from "../models/game-session";

@Pipe({
  name: 'sessionStatus'
})
export class SessionStatusPipe implements PipeTransform {

  transform(value: GameSessionStatus | undefined, ...args: unknown[]): string {
    switch (value) {
      case 'ACTIVE':
        return 'general.state.active';
      case 'AWARD_CEREMONY':
        return 'general.state.award_ceremony';
      case 'FINISHED':
        return 'general.state.finished';
    }
    return ''+value;
  }

}
