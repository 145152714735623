// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDStCVJM0BbAqNvSKQPMsApmlsYmvz6xbM",
    authDomain: "quiznet-dev.firebaseapp.com",
    projectId: "quiznet-dev",
    storageBucket: "quiznet-dev.appspot.com",
    messagingSenderId: "628536213486",
    appId: "1:628536213486:web:d51fa978a74215126c18ff"
  },
  masterAuthUrl: 'https://quiznet-dev.web.app/game-masters/auth',
  apiUrl: 'https://quiznet-backend-6cfdljwu4q-ey.a.run.app',
  webappUrl: 'https://quiznet-dev.web.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
