import { Pipe, PipeTransform } from '@angular/core';
import {GameSessionModeType} from "../models/game-session-mode-type";

@Pipe({
  name: 'modeType'
})
export class ModeTypePipe implements PipeTransform {

  transform(value: GameSessionModeType | undefined, ...args: unknown[]): unknown {
    switch (value) {
      case "ANSWER":
        return 'Freitext ';
      case "BUZZER":
        return 'Buzzer';
      case "SINGLE_CHOICE":
      case "MULTIPLE_CHOICE":
        return 'Auswahl';
      case "SORT":
        return 'Sortieren';
      case "ESTIMATE":
        return 'Schätzen';
      case "QUICK_ANSWER":
        return 'Schnellraten';
    }
    return value;
  }

}
