import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {LoadingOverlayService} from "../../services/loading-overlay.service";

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnInit {
  isVisible?: Observable<boolean>;

  constructor(private loadingOverlayService: LoadingOverlayService) { }

  ngOnInit() {
    this.isVisible = this.loadingOverlayService.isVisible();
  }
}
