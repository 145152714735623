<div class="sort-answers" [class.vertical]="vertical" [class.grid]="grid" [class.disabled]="disabled"
     cdkDropList
     [cdkDropListDisabled]="disabled"
     [cdkDropListOrientation]="vertical ? 'vertical' : 'horizontal'"
     (cdkDropListDropped)="sortDropped($event)">
  <button class="btn" *ngFor="let s of sortAnswers; let i = index" cdkDrag
          [disabled]="disabled">
    <span style="flex: 1;" *ngIf="!vertical"></span>
    {{getLabelForAnswer(s)}}
    <span style="flex: 1;"></span>
    <ng-container *ngIf="editable">
      <mat-icon (click)="editItem(s, i)">edit</mat-icon>
      <mat-icon (click)="deleteItem(s, i)" [class.disabled]="sortAnswers.length <= 2">delete</mat-icon>
      <mat-icon cdkDragHandle class="drag-handle">drag_handle</mat-icon>
    </ng-container>
  </button>
  <button class="btn" *ngIf="editable" [disabled]="disabled || sortAnswers.length >= 6" (click)="addItem()">
    <mat-icon>add</mat-icon>
  </button>
</div>
