import {HttpBackend, HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the HttpClientNoAuthInterceptor provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class HttpClientNoAuthInterceptor extends HttpClient {

  constructor(handler: HttpBackend) {
    super(handler);
  }

}
