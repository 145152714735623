import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LangSelectComponent implements OnInit {

  currentLang: string | undefined

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang
    console.log("LANGUAGE", this.currentLang)
  }

  changeLanguage() {
    const languageToChange = this.currentLang !== 'de' ? 'de' : 'en';
    this.translateService.use(languageToChange).subscribe(() => {
      console.log(`language changed to ${languageToChange}`);
      this.currentLang = languageToChange
      localStorage.setItem('language', languageToChange);
    }, e => {
      console.error(`unable to load language ${languageToChange}`, e);
    });
  }

}
