import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayService {
  private isVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  public show() {
    this.isVisibleSubject.next(true);
  }

  public hide() {
    this.isVisibleSubject.next(false);
  }

  public isVisible(): Observable<boolean> {
    return this.isVisibleSubject.asObservable();
  }

  public async run(fn: () => Promise<void>) {
    const timeout = setTimeout(() => this.show(), 250);
    try {
      await fn();
      clearTimeout(timeout);
    } catch (e) {
      throw e;
    } finally {
      clearTimeout(timeout);
      this.hide();
    }
  }
}
