import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";
import {GameSetQuestionAnswer} from "../../../shared/models/game-set-question-answer";

@Component({
  selector: 'app-game-set-question-answer-edit-dialog',
  templateUrl: './game-set-question-answer-edit-dialog.component.html',
  styleUrls: ['./game-set-question-answer-edit-dialog.component.scss']
})
export class GameSetQuestionAnswerEditDialogComponent implements OnInit {

  dialogModel: Partial<GameSetQuestionAnswer> = {
    key: '',
    label: '',
  };

  constructor(
    private dialogRef: MatDialogRef<GameSetQuestionAnswerEditDialogComponent, GameSetQuestionAnswerEditDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: GameSetQuestionAnswerEditDialogData,
  ) {
    this.dialogModel.key = data?.item?.key ?? '';
    this.dialogModel.label = data?.item?.label ?? '';
  }

  ngOnInit(): void {
  }

  cancelDialog() {
    this.dialogRef?.close();
  }

  save() {
    this.dialogRef?.close({
      action: 'ok',
      item: Object.assign({}, this.data?.item, this.dialogModel),
    });
  }

  static open(matDialog: MatDialog, data: GameSetQuestionAnswerEditDialogData): Observable<GameSetQuestionAnswerEditDialogResult | undefined> {
    return matDialog.open<any, GameSetQuestionAnswerEditDialogData, GameSetQuestionAnswerEditDialogResult>(
      GameSetQuestionAnswerEditDialogComponent,
      {
        data: data
      }
    )
      .afterClosed()
      .pipe(first());
  }
}

export interface GameSetQuestionAnswerEditDialogData {
  type: 'add' | 'edit';
  item?: Partial<GameSetQuestionAnswer>;
}

export interface GameSetQuestionAnswerEditDialogResult {
  action: 'ok' | 'cancel';
  item?: Partial<GameSetQuestionAnswer>;
}
