import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'game-masters',
    loadChildren: () => import('./modules/game-masters/game-masters.module').then((m) => m.GameMastersModule)
  },
  {
    path: 'game-sessions',
    loadChildren: () => import('./modules/game-sessions/game-sessions.module').then((m) => m.GameSessionsModule)
  },
  {
    path: '**',
    redirectTo: 'game-sessions'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
