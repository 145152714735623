<div class="multiple-choice-answers" [class.vertical]="vertical" [class.grid]="grid" [class.disabled]="disabled">
  <ng-container *ngIf="answers && answers.length > 0; else oldTemplate">
    <button class="btn" *ngFor="let a of answers; let i = index"
            [class.btn-selected]="a.key === answer" (click)="onAnswerChange(a.key)"
            [disabled]="disabled">
      <span style="flex: 1;" *ngIf="!vertical"></span>
      {{getLabelForAnswer(a.key)}}
      <mat-icon *ngIf="a.key === answer" style="margin-left: 5px;">check</mat-icon>
      <span style="flex: 1;"></span>
      <ng-container *ngIf="editable">
        <mat-icon (click)="editItem($event, a.key, i)">edit</mat-icon>
        <mat-icon (click)="deleteItem($event, a.key, i)" [class.disabled]="answers.length <= 2">delete</mat-icon>
      </ng-container>
    </button>
  </ng-container>
  <ng-template #oldTemplate>
    <button class="btn" [class.btn-selected]="answer === 'A'" (click)="onAnswerChange('A')" [disabled]="disabled">
      A
    </button>
    <button class="btn" [class.btn-selected]="answer === 'B'" (click)="onAnswerChange('B')" [disabled]="disabled">
      B
    </button>
    <button class="btn" [class.btn-selected]="answer === 'C'" (click)="onAnswerChange('C')" [disabled]="disabled">
      C
    </button>
    <button class="btn" [class.btn-selected]="answer === 'D'" (click)="onAnswerChange('D')" [disabled]="disabled">
      D
    </button>
  </ng-template>
  <button class="btn" *ngIf="editable" [disabled]="disabled || (answers && answers.length >= 6)" (click)="addItem()">
    <mat-icon>add</mat-icon>
  </button>
</div>
