import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameSet} from "../../models/game-set";
import {GameSessionModeType} from "../../models/game-session-mode-type";

@Component({
  selector: 'app-game-set-type-filter',
  templateUrl: './game-set-type-filter.component.html',
  styleUrls: ['./game-set-type-filter.component.scss']
})
export class GameSetTypeFilterComponent implements OnInit {
  @Input()
  gameSet?: GameSet;

  @Input()
  selectedType?: GameSessionModeType | null;

  @Output()
  selectedTypeChange = new EventEmitter<GameSessionModeType | null>();

  @Input()
  disabled = false;

  get types(): GameSessionModeType[] {
    const gameSet = this.gameSet;
    const types = new Set<GameSessionModeType>();
    if (gameSet) {
      for (const q of gameSet.questions) {
        types.add(q.type);
      }
    }
    return Array.from(types);
  }

  constructor() { }

  ngOnInit(): void {
  }

  selectType(type: GameSessionModeType) {
    if (this.selectedType === type) {
      this.selectedType = null;
      this.selectedTypeChange.emit(null);
    } else {
      this.selectedType = type;
      this.selectedTypeChange.emit(type);
    }
  }
}
