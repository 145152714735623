<mat-form-field class="lang-dropdown">
  <mat-select   class="alarm-dropdown" [value]="currentLang" (valueChange)="changeLanguage()">
    <mat-select-trigger class="lang-dropdown-trigger" *ngIf="currentLang === 'de'">
      <img width="25" src="assets/images/de.png"/>
    </mat-select-trigger>
    <mat-select-trigger class="lang-dropdown-trigger" *ngIf="currentLang === 'en'">
      <img width="25" src="assets/images/en.png"/>
    </mat-select-trigger>
    <mat-option value="en">
      <img width="25" src="assets/images/en.png"/>
    </mat-option>
    <mat-option value="de">
      <img width="25" src="assets/images/de.png"/>
    </mat-option>
  </mat-select>
</mat-form-field>

