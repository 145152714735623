import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {from, Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {AngularFireAuth} from "@angular/fire/auth";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private fireAuth: AngularFireAuth
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('HttpRequestInterceptor.intercept', req);

    return from(this.fireAuth.idToken).pipe(mergeMap((idToken => {

      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`
        }
      });

      return next.handle(request);
    })));
  }

}

@NgModule({
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true}
  ]
})
export class InterceptorModule {
}
