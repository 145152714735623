import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {GameSetQuestionAnswer} from "../../models/game-set-question-answer";
import {
  GameSetQuestionAnswerEditDialogComponent
} from "../../../game-masters/components/game-set-question-answer-edit-dialog/game-set-question-answer-edit-dialog.component";

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
];

@Component({
  selector: 'app-multiple-choice-answer',
  templateUrl: './multiple-choice-answer.component.html',
  styleUrls: ['./multiple-choice-answer.component.scss']
})
export class MultipleChoiceAnswerComponent implements OnInit, OnChanges {
  @Input()
  vertical = false;

  @Input()
  grid = false;

  @Input()
  editable = false;

  @Input()
  showKeyForAnswer = true;

  @Input()
  disabled = false;

  @Input()
  answer: 'A' | 'B' | 'C' | 'D' | string | null | undefined;

  @Output()
  answerChange = new EventEmitter<'A' | 'B' | 'C' | 'D' | string>();

  @Input()
  answers: Array<Partial<GameSetQuestionAnswer>> | undefined = [
    {key: 'A'},
    {key: 'B'},
    {key: 'C'},
    {key: 'D'},
  ];

  @Output()
  answersChange = new EventEmitter<Array<GameSetQuestionAnswer>>();

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const answers = changes?.answers?.currentValue;
    console.log('MultipleChoiceAnswerComponent.ngOnChanges');
    console.log('MultipleChoiceAnswerComponent.ngOnChanges answers', answers);
    if((!this.answers || this.answers.length < 1) && (!Array.isArray(answers) || answers.length < 1)) {
      this.answers = [
        {key: 'A'},
        {key: 'B'},
        {key: 'C'},
        {key: 'D'},
      ];
    }
  }

  onAnswerChange(answer?: 'A' | 'B' | 'C' | 'D' | string) {
    this.answer = answer;
    this.answerChange.emit(answer);

    // for (const a of this.answers!) {
    //   a.isCorrect = a.key === answer;
    // }
    this.answersChange.emit(this.answers as Array<GameSetQuestionAnswer>);
  }

  editItem($event: MouseEvent, answer: string | undefined, index: number) {
    console.log('editItem', answer);
    $event?.stopPropagation();
    const a = this.answers?.find(a => a.key === answer);
    GameSetQuestionAnswerEditDialogComponent.open(this.dialog, {type: 'edit', item: a}).subscribe(async result => {
      if (result?.action === 'ok' && result?.item != null) {
        const item = result.item;
        console.log('ok', item);
        this.answers!.splice(index, 1, item);
        this.answersChange.emit(this.answers as Array<GameSetQuestionAnswer>);
      } else {
        console.log('cancel');
      }
    });
  }

  deleteItem($event: MouseEvent, answer: string | undefined, index: number) {
    console.log('deleteItem', answer);
    $event?.stopPropagation();
    if (this.answers!.length <= 2) {
      alert('Die letzten zwei Antworten können nicht gelöscht werden');
      return;
    }
    const label = this.getLabelForAnswer(answer);
    if (confirm(`Möchtest du die Antwort "${label}" wirklich löschen?`)) {
      const splice = this.answers!.splice(index, 1);
      this.answersChange.emit(this.answers as Array<GameSetQuestionAnswer>);
      this.answers!.forEach((a, i) => {
        a.key = letters[i];
      });
      if (!this.answers?.some(a => a.key === this.answers)) {
          this.onAnswerChange(this.answers![this.answers!.length - 1].key);
      }
    }
  }

  getLabelForAnswer(answer?: string) {
    const a = this.answers?.find(a => a.key === answer);
    return a?.label ? `${this.showKeyForAnswer ? a.key + ': ' : ''}${a.label}` : answer;
  }

  addItem() {
    console.log('addItem');
    let letter = letters.find(l => !this.answers!.some(a => a.key === l));
    GameSetQuestionAnswerEditDialogComponent.open(this.dialog, {
      type: 'add', item: {
        key: letter,
      }
    }).subscribe(async result => {
      if (result?.action === 'ok' && result?.item != null) {
        const item = result.item;
        console.log('ok', item);
        this.answers!.push(item);
        this.answersChange.emit(this.answers as Array<GameSetQuestionAnswer>);
      } else {
        console.log('cancel');
      }
    });
  }
}
