import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'answeredAtDiff'
})
export class AnsweredAtDiffPipe implements PipeTransform {

  transform(ms: number | null | undefined, ...args: unknown[]): unknown {
    if(typeof ms === 'number') {
      return Math.abs(ms / 1000).toFixed(2);
    }
    return ms;
  }

}
