<h1 mat-dialog-title>Antwort</h1>
<div mat-dialog-content class="settings-dialog">
  <mat-form-field appearance="outline">
    <mat-label>Antwort</mat-label>
    <input matInput type="text" [(ngModel)]="dialogModel.label">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button class="btn" (click)="cancelDialog()">Abbrechen</button>
  <button class="btn btn-blue" (click)="save()"
          [disabled]="dialogModel.label == null || dialogModel.label.trim().length < 1">
    Okay
  </button>
</div>
