import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  confirmText = '';
  buttonText = '';
  buttonColor: ConfirmDialogButtonColor = 'blue';

  get buttonClasses() {
    return {
      'btn-blue': this.buttonColor === 'blue',
      'btn-red': this.buttonColor === 'red',
      'btn-yellow': this.buttonColor === 'yellow',
    };
  }

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent, ConfirmDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {
    this.confirmText = data?.confirmText ?? 'Möchtest du diese Aktion wirklich ausführen?';
    this.buttonText = data?.buttonText ?? 'Okay';
    this.buttonColor = data?.buttonColor ?? 'blue';
  }

  ngOnInit(): void {
  }

  cancelDialog() {
    this.dialogRef?.close();
  }

  confirm() {
    this.dialogRef?.close({
      action: 'ok',
    });
  }

  static async confirm(matDialog: MatDialog, confirmText: string, buttonText?: string, buttonColor?: ConfirmDialogButtonColor): Promise<boolean> {
    const data: ConfirmDialogData = {
      confirmText,
    };

    if (buttonText) {
      data.buttonText = buttonText;
    }

    if (buttonColor) {
      data.buttonColor = buttonColor;
    }

    const result = await ConfirmDialogComponent.open(matDialog, data).toPromise();
    return result?.action === 'ok';
  }

  static async confirmDelete(matDialog: MatDialog, confirmText: string): Promise<boolean> {
    return await this.confirm(matDialog, confirmText, 'Löschen', 'red');
  }

  static open(matDialog: MatDialog, data: ConfirmDialogData): Observable<ConfirmDialogResult | undefined> {
    return matDialog.open<any, ConfirmDialogData, ConfirmDialogResult>(
      ConfirmDialogComponent,
      {
        data: data
      }
    )
      .afterClosed()
      .pipe(first());
  }
}

export type ConfirmDialogButtonColor = 'blue' | 'red' | 'yellow';

export interface ConfirmDialogData {
  confirmText: string;
  buttonText?: string;
  buttonColor?: ConfirmDialogButtonColor;
}

export interface ConfirmDialogResult {
  action: 'ok' | 'cancel';
}
