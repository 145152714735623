import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {GameSetQuestionAnswer} from "../../models/game-set-question-answer";
import {
  GameSetQuestionAnswerEditDialogComponent
} from "../../../game-masters/components/game-set-question-answer-edit-dialog/game-set-question-answer-edit-dialog.component";
import {MatDialog} from "@angular/material/dialog";

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
];

@Component({
  selector: 'app-sort-answer',
  templateUrl: './sort-answer.component.html',
  styleUrls: ['./sort-answer.component.scss']
})
export class SortAnswerComponent implements OnInit, OnChanges {
  @Input()
  vertical = false;

  @Input()
  grid = false;

  @Input()
  editable = false;

  @Input()
  disabled = false;

  @Input()
  showKeyForAnswer = true;

  @Input()
  answer: string | undefined = '';

  @Output()
  answerChange = new EventEmitter<string>();

  @Input()
  answers: Array<Partial<GameSetQuestionAnswer>> | undefined = [
    { key: 'A' },
    { key: 'B' },
    { key: 'C' },
    { key: 'D' },
  ];

  @Output()
  answersChange = new EventEmitter<Array<GameSetQuestionAnswer>>();

  sortAnswers = ['A', 'B', 'C', 'D'];


  // get sortAnswers(): string[] {
  //   const answer = this.answer;
  //   return answer != null && answer.indexOf(',') > -1 ? answer.split(',') : ['A', 'B', 'C', 'D'];
  // }
  //
  // set sortAnswers(answer: string[]) {
  //   this.answer = answer.join(',');
  //   this.answerChange.emit(this.answer);
  // }

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    console.log('SortAnswerComponent.ngOnInit', this.sortAnswers, this.answers);
    setTimeout(() => this.answerChange.emit(this.sortAnswers.join(',')));
    setTimeout(() => this.answersChange.emit(this.answers as Array<GameSetQuestionAnswer>));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const answer = changes?.answer?.currentValue;
    const answers = changes?.answers?.currentValue;
    console.log('SortAnswerComponent.ngOnChanges');
    console.log('SortAnswerComponent.ngOnChanges answer', answer);
    console.log('SortAnswerComponent.ngOnChanges answers', answers);
    if (typeof answer === 'string' && answer.indexOf(',') > -1) {
      this.sortAnswers = answer.split(',');
      if(!Array.isArray(this.answers) || this.answers.length < 1) {
        this.answers = answer.split(',').map(s => {
          return {key: s};
        });
      }
    }
  }


  sortDropped($event: CdkDragDrop<string[]>) {
    console.log('SortAnswerComponent.sortDropped', $event);
    const sortAnswers = this.sortAnswers;
    const fromIndex = $event.previousIndex;
    const toIndex = $event.currentIndex;
    const element = sortAnswers[fromIndex];
    sortAnswers.splice(fromIndex, 1);
    sortAnswers.splice(toIndex, 0, element);
    this.sortAnswers = sortAnswers;
    // this.answersChange.emit(sortAnswers as Array<GameSetQuestionAnswer>);
    this.answerChange.emit(sortAnswers.join(','));
    this.sortDroppedAnswers($event);
  }

  sortDroppedAnswers($event: CdkDragDrop<string[]>) {
    console.log('SortAnswerComponent.sortDroppedAnswers', $event);
    const answers = this.answers!;
    const fromIndex = $event.previousIndex;
    const toIndex = $event.currentIndex;
    const element = answers[fromIndex];
    answers.splice(fromIndex, 1);
    answers.splice(toIndex, 0, element);
    this.answers = answers;
    this.answersChange.emit(answers as Array<GameSetQuestionAnswer>);
  }

  editItem(answer: string, index: number) {
    console.log('editItem', answer);
    const a = this.answers?.find(a => a.key === answer);
    GameSetQuestionAnswerEditDialogComponent.open(this.dialog, {type: 'edit', item: a}).subscribe(async result => {
      if (result?.action === 'ok' && result?.item != null) {
        const item = result.item;
        console.log('ok', item);
        this.answers!.splice(index, 1, item);
        this.answersChange.emit(this.answers as Array<GameSetQuestionAnswer>);
      } else {
        console.log('cancel');
      }
    });
  }

  deleteItem(answer: string, index: number) {
    console.log('deleteItem', answer);
    if (this.sortAnswers.length <= 2) {
      alert('Die letzten zwei Antworten können nicht gelöscht werden');
      return;
    }
    const label = this.getLabelForAnswer(answer);
    if (confirm(`Möchtest du die Antwort "${label}" wirklich löschen?`)) {
      this.sortAnswers.splice(index, 1);
      this.answerChange.emit(this.sortAnswers.join(','));

      this.answers?.splice(index, 1);
      this.answersChange.emit(this.answers as Array<GameSetQuestionAnswer>);
    }
  }

  getLabelForAnswer(answer: string) {
    const a = this.answers?.find(a => a.key === answer);
    return a?.label ? `${this.showKeyForAnswer ? a.key + ': ' : ''}${a.label}` : answer;
  }

  addItem() {
    console.log('addItem');
    let letter = letters.find(l => !this.sortAnswers.some(a => a === l));
    GameSetQuestionAnswerEditDialogComponent.open(this.dialog, {type: 'add', item: {
        key: letter,
      }}).subscribe(async result => {
      if (result?.action === 'ok' && result?.item != null) {
        const item = result.item;
        console.log('ok', item);
        this.answers!.push(item);
        this.answersChange.emit(this.answers as Array<GameSetQuestionAnswer>);
        this.sortAnswers.push(item.key!);
        this.answerChange.emit(this.sortAnswers.join(','));
      } else {
        console.log('cancel');
      }
    });
  }
}
