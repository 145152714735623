import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameSet} from "../../models/game-set";

@Component({
  selector: 'app-game-set-group-filter',
  templateUrl: './game-set-group-filter.component.html',
  styleUrls: ['./game-set-group-filter.component.scss']
})
export class GameSetGroupFilterComponent implements OnInit {

  @Input()
  gameSet?: GameSet;

  @Input()
  selectedGroup?: string | null;

  @Output()
  selectedGroupChange = new EventEmitter<string | null>();

  @Input()
  disabled = false;

  get groups(): string[] {
    const gameSet = this.gameSet;
    const groups = new Set<string>();
    if (gameSet) {
      for (const q of gameSet.questions) {
        if (typeof q.group === 'string') {
          groups.add(q.group);
        }
      }
    }
    return Array.from(groups);
  }

  constructor() { }

  ngOnInit(): void {
  }

  selectGroup(group: string) {
    if (this.selectedGroup === group) {
      this.selectedGroup = null;
      this.selectedGroupChange.emit(null);
    } else {
      this.selectedGroup = group;
      this.selectedGroupChange.emit(group);
    }
  }
}
