import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnsweredAtDiffPipe } from './pipes/answered-at-diff.pipe';
import { ModeTypePipe } from './pipes/mode-type.pipe';
import { MultipleChoiceAnswerComponent } from './components/multiple-choice-answer/multiple-choice-answer.component';
import { SortAnswerComponent } from './components/sort-answer/sort-answer.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { SessionStatusPipe } from './pipes/session-status.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import { GameSetGroupFilterComponent } from './components/game-set-group-filter/game-set-group-filter.component';
import {MatChipsModule} from "@angular/material/chips";
import { GameSetTypeFilterComponent } from './components/game-set-type-filter/game-set-type-filter.component';
import {MatIconModule} from "@angular/material/icon";
import {HttpClientNoAuthInterceptor} from "./http-client-no-auth-interceptor";
import { LangSelectComponent } from './components/lang-select/lang-select.component';
import {MatSelectModule} from "@angular/material/select";

@NgModule({
    declarations: [
        AnsweredAtDiffPipe,
        ModeTypePipe,
        MultipleChoiceAnswerComponent,
        SortAnswerComponent,
        LoadingSpinnerComponent,
        LoadingOverlayComponent,
        SessionStatusPipe,
        ConfirmDialogComponent,
        GameSetGroupFilterComponent,
        GameSetTypeFilterComponent,
        LangSelectComponent
    ],
  exports: [
    AnsweredAtDiffPipe,
    ModeTypePipe,
    MultipleChoiceAnswerComponent,
    SortAnswerComponent,
    LoadingOverlayComponent,
    SessionStatusPipe,
    GameSetGroupFilterComponent,
    GameSetTypeFilterComponent,
    LangSelectComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatDialogModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule
  ],
  providers: [
    HttpClientNoAuthInterceptor
  ]
})
export class SharedModule { }
